import './header.css';
import React from 'react';

function Header() {
  
  return (
      <div class="ui menu">
        <div class="header item">
          <h2> Welcome to PasteOnEth! </h2>
        </div>
      </div>
  );
}

export default Header;